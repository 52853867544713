<template>
    <div :id="type === 'event' ? '' : 'form'" class="w-full pt-20 sm:pt-32 pb-20">
        <div class="m-auto whitespace-nowrap overflow-hidden pt-5 animated-block">
            <span class="font-bold text-tp-yellow text-4xl sm:text-5xl md:text-6xl lg:text-8xl uppercase inline-block marquee">
                contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos / contáctanos /
            </span>
        </div>

        <div class="flex flex-col gap-6 px-8 lg:px-28 pt-10">
            <div class="hidden md:block animated-block">
                <h1 v-if="type === 'dj'" class="font-semibold text-2xl sm:text-3xl md:text-5xl w-full xl:w-2/3">
                    Impulsa tu carrera como DJ <br>
                    dando la oportunidad a tu<br>
                    público de elegir la música.
                </h1>
                <h1 v-else-if="type === 'disco'" class="font-semibold text-2xl sm:text-3xl md:text-5xl w-full xl:w-2/3">
                    Ofrece a tus clientes el poder<br>
                    de decidir la música y crea noches<br>
                    inolvidables en tu discoteca.
                </h1>
                <h1 v-else class="font-semibold text-2xl sm:text-3xl md:text-5xl w-full xl:w-2/3">
                    ¿Tienes algo que contarnos?
                </h1>
            </div>

            <div class="md:hidden animated-block">
                <h1 v-if="type === 'dj'" class="font-semibold text-2xl sm:text-3xl md:text-5xl w-full xl:w-2/3">
                    Impulsa tu carrera como DJ
                    dando la oportunidad a tu
                    público de elegir la música.
                </h1>
                <h1 v-else-if="type === 'disco'" class="font-semibold text-2xl sm:text-3xl md:text-5xl w-full xl:w-2/3">
                    Ofrece a tus clientes el poder
                    de decidir la música y crea noches
                    inolvidables en tu discoteca.
                </h1>
                <h1 v-else class="font-semibold text-2xl sm:text-3xl md:text-5xl w-full xl:w-2/3">
                    ¿Tienes algo que contarnos?
                </h1>
            </div>

            <div class="flex flex-col gap-14 sm:gap-10 text-xl text-tp-yellow w-full xl:w-1/2 md:pt-6">
                <div v-if="type === 'dj' || type === 'disco'" class="flex flex-col gap-4 animated-block">
                    <div v-if="type === 'dj'" class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6">
                        <input v-model="form.name" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Nombre y apellidos" required>
                    </div>
                    <div v-else-if="type === 'disco'" class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6">
                        <input v-model="form.name" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Nombre discoteca" required>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6" >
                        <input v-model="form.email" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Email" required>
                        <input v-model="form.password" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="password" placeholder="Password" required>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6">
                        <input v-model="form.comment" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Comentarios, preguntas..." required>
                    </div>
                </div>
                <div v-else class="flex flex-col gap-4 animated-block">
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6" >
                        <input v-model="form.name" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Nombre y apellidos" required>
                        <input v-model="form.email" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Email" required>
                    </div>
                    <div class="flex flex-col sm:flex-row gap-4 sm:gap-6 transition-opacity duration-500 ease-in-out sm:mb-6">
                        <input v-model="form.comment" class="bg-transparent outline-none border-b border-tp-yellow p-2 w-full" type="text" placeholder="Comentarios, preguntas..." required>
                    </div>
                </div>
                <div class="flex flex-col gap-6">          
                    <button @click="sendEmail" class="flex items-center gap-2 sm:gap-6 w-full cursor-pointer text-tp-yellow text-4xl sm:text-6xl font-semibold uppercase no-underline animated-block" href="">Enviar
                        <lord-icon
                            src="https://cdn.lordicon.com/whtfgdfm.json"
                            trigger="hover"
                            colors="primary:#F9F871" class="w-10 h-10 sm:w-24 sm:h-24">
                        </lord-icon>
                    </button>
                    <p v-if="formIncompleteMessage" class="text-red-500">{{ formIncompleteMessage }}</p>
                    <p v-if="submitStatus === 'success'" class="text-green-500">¡Correo enviado con éxito!</p>
                    <p v-if="submitStatus === 'error'" class="text-red-500">Error al enviar el correo.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import axios from 'axios';

const props = defineProps({
  type: String,
})

const formIncompleteMessage = ref('');

const form = ref({
    name: '',
    email: '',
    password: '',
    role: props.type,
    comment:''
});

const isFormComplete = computed(() => {
  return form.value.name && form.value.email && form.value.comment;
});

const submitStatus = ref('');

const sendEmail = async () => {
    if (!isFormComplete.value) {
        console.log(form.value)
        formIncompleteMessage.value = 'Por favor, rellena todos los campos antes de enviar.';
        return; 
    }  
    formIncompleteMessage.value = '';
  try {
    const url = `https://api.dev.tuparty.club/contact`;
    const formData = new FormData();

    for (const key in form.value) {
      formData.append(key, form.value[key]);
    }

    const method = 'post';
    
    
    const response = await axios.request({
        method,
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    });
    
    if (response.status === 200) {
      submitStatus.value = 'success';
      resetForm();
    } else {
      throw new Error('Error al enviar el correo.');
    }
  } catch (error) {
    console.error('Error:', error);
    submitStatus.value = 'error';
  }
};

const resetForm = () => {
  form.value.name = '';
  form.value.email = '';
  form.value.password = '';
  form.value.comment = '';
};

</script>

<style scoped>
.marquee {
    padding-left: -10%;
    animation: marquee-animation 80s linear infinite;
}

@keyframes marquee-animation {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.dot {
    transition: transform 0.3s ease;
}
.translate-x-full {
    transform: translateX(100%);
}
.translate-x-0 {
    transform: translateX(0);
}

.custom-date {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

input[type="date"]:not(:valid) {
  color: #9298A4; 
}

input[type="date"]:valid {
  color: #f9f871; 
}

.date-input {
  width: 100%;
  padding: 10px;
  border-bottom: 1px solid #f9f871; 
  background: transparent;
  padding-right: 2.5rem; 
}

.date-input::-webkit-calendar-picker-indicator {
  opacity: 0;
  position: absolute;
  width: 100%;
}

.calendar-icon {
  position: absolute;
  right: 10px;
  width: 1.5rem;
  height: 1.5rem;
  color: #f9f871;
  pointer-events: none;
}
</style>