<template> 
<div class="flex justify-center w-full pb-20 px-8 lg:px-28 animated-block-y">
  <div
    class="relative w-full max-w-4xl mx-auto overflow-hidden rounded-2xl yellow-shadow"
    @mousedown="startDrag"
    @mousemove="onDrag"
    @mouseup="endDrag"
    @mouseleave="endDrag"
    @touchstart="startDrag"
    @touchmove="onDrag"
    @touchend="endDrag"
  >
    <div
      class="flex transition-transform duration-700 ease-in-out"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div v-for="(image, index) in images" :key="index" class="w-full flex-shrink-0">
        <img :src="image" class="w-full object-fill rounded-lg" />
      </div>
    </div>
    
    <!-- Botones de navegación -->
    <button
      class="absolute top-1/2 left-2 transform -translate-y-1/2 bg-black bg-opacity-50 p-2 rounded-full text-white"
      @click="prevSlide"
    >
      &#10094;
    </button>
    <button
      class="absolute top-1/2 right-2 transform -translate-y-1/2 bg-black bg-opacity-50 p-2 rounded-full text-white"
      @click="nextSlide"
    >
      &#10095;
    </button>
  </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import cabinApp from '@/assets/images/cabin-app.webp';
import djLanyard from '@/assets/images/dj-lanyard-visuals.webp';
import lanyards from '@/assets/images/lanyards.webp';
import qrScanning from '@/assets/images/qr-scanning.webp';
import partycollage from '@/assets/images/party-collage.webp';

const images = ref([djLanyard, qrScanning, cabinApp, partycollage, lanyards]);
const currentIndex = ref(0);
let interval = null;
let startX = 0;
let isDragging = false;

const startAutoSlide = () => {
  clearInterval(interval);
  interval = setInterval(nextSlide, 3000);
};

const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
  startAutoSlide();
};

const prevSlide = () => {
  currentIndex.value = (currentIndex.value - 1 + images.value.length) % images.value.length;
  startAutoSlide();
};

const startDrag = (event) => {
  isDragging = true;
  startX = event.touches ? event.touches[0].clientX : event.clientX;
  clearInterval(interval);
};

const onDrag = (event) => {
  if (!isDragging) return;
  const currentX = event.touches ? event.touches[0].clientX : event.clientX;
  const difference = startX - currentX;

  if (Math.abs(difference) > 50) {
    if (difference > 0) {
      nextSlide();
    } else {
      prevSlide();
    }
    isDragging = false;
  }
};

const endDrag = () => {
  isDragging = false;
  startAutoSlide();
};

onMounted(() => {
  startAutoSlide();
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>

<style scoped>
button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
</style>
