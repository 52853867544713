<template>
  <div class="flex flex-col items-center w-full mb-20">
    <h1 class="font-bold text-center text-4xl sm:text-5xl xl:text-6xl pb-10 pointer-events-none z-10 animated-block-y">Opiniones</h1>
    <div class="w-full mx-auto shadow-lg rounded-2xl text-center relative bg-tp-black border-2 border-tp-yellow overflow-hidden animated-block-y">
        <div class="flex text-tp-black font-bold text-xl pt-6 px-6 xs:pt-12 xs:px-12">
        <p class="bg-tp-yellow px-4 py-2 rounded-lg"><span class="text-yellow-400 text-2xl text-outlined pr-1">&#9733;</span> {{ averageRating }}</p>
        </div>
        <div class="relative flex flex-col gap-4 px-6 xs:px-12">
            <h2 class="text-3xl sm:text-5xl xl:text-6xl text-left font-bold max-w-2xl mt-6">Lo que dicen nuestros clientes</h2>
            <div class="lg:absolute bottom-2 right-12 flex gap-4">
                <button @click="prevReview" :disabled="isFirstReview" class="w-10 h-10 lg:w-14 lg:h-14 bg-tp-yellow text-tp-black rounded-full hover:bg-opacity-80 text-xl lg:text-3xl" :class="{ 'opacity-50 cursor-not-allowed': isFirstReview }">
                    ←
                </button>
                <button @click="nextReview" :disabled="isLastReview" class="w-10 h-10 lg:w-14 lg:h-14 bg-tp-yellow text-tp-black rounded-full hover:bg-opacity-80 text-xl lg:text-3xl" :class="{ 'opacity-50 cursor-not-allowed': isLastReview }">
                    →
                </button>
            </div>
        </div>
        <div class="relative w-full overflow-hidden pb-6 px-6 xs:pb-12 xs:px-12"
        @mousedown="startDrag"
        @mousemove="onDrag"
        @mouseup="endDrag"
        @mouseleave="endDrag"
        @touchstart="startDrag"
        @touchmove="onDrag"
        @touchend="endDrag">
            <div
            class="flex transition-transform duration-700 ease-in-out pt-6 slider"
            :style="setSliderTransform"
            >
                <div v-for="(review, index) in reviews" :key="index" class="flex gap-4 w-full md:w-1/2 flex-shrink-0">
                    <ReviewCard 
                        :rating="review.rating" 
                        :comment="review.comment" 
                        :name="review.name" 
                    />
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';
import ReviewCard from '@/components/DJ/ReviewCard.vue';

const props = defineProps({
  type: String,
})

let startX = 0;
let isDragging = false;


// const reviews = ref([
//   { rating: 5, comment: 'Me gustó mucho la dinámica, definitivamente la volveré a usar.', name: 'Carlos R.' },
//   { rating: 4, comment: 'Muy divertido, me encantó poder votar por mis canciones favoritas.', name: 'María L.' },
//   { rating: 5, comment: 'Una experiencia brutal, nunca me había sentido tan conectado con la música en una fiesta.', name: 'Andrés P.' },
//   { rating: 3, comment: 'Interesante propuesta, tiene potencial para hacer las fiestas aún mejores.', name: 'Lucía G.' },
//   { rating: 5, comment: 'Mis amigos y yo lo usamos toda la noche. ¡Nos encantó!', name: 'Pedro M.' }
// ]);

const reviews = props.type === 'disco' ? ref([
    { rating: 5, comment: 'Desde que la usamos, el ambiente en la discoteca es otro. Un antes y un después para nuestra sala.', name: 'Raúl T.' },
    { rating: 5, comment: 'Hemos notado un aumento en el consumo en la barra. A los clientes les encanta elegir la música.', name: 'Lorena S.' },
    { rating: 4, comment: 'Muy buena herramienta para interactuar con el público. Ha hecho nuestras noches mucho más dinámicas.', name: 'Alberto G.' },
    { rating: 5, comment: 'Los DJs están encantados y el ambiente en la pista ha mejorado muchísimo. 100% recomendado.', name: 'Carlos D.' },
    { rating: 4, comment: 'Desde que usamos TuParty, la gente se queda más tiempo en la discoteca. ¡Muy top!', name: 'Javier M.' }
  ]) 
  :
  ref([
    { rating: 5, comment: 'Lo mejor es ver cómo la gente reacciona cuando suena su canción favorita.', name: 'Pedro P.' },
    { rating: 5, comment: 'Está genial ver qué canciones quiere la gente y, además, genera un ingreso extra para nosotros.', name: 'Luis M.' },
    { rating: 4, comment: 'Es una experiencia diferente. La gente disfruta mucho más al elegir lo que quiere escuchar. Muy recomendable.', name: 'Álvaro H.' },
    { rating: 5, comment: 'Me hace sentir más conectado con el público.', name: 'Martín L.' },
    { rating: 3, comment: 'Ver las canciones más votadas me ayuda a adaptarme mejor. Aún así, hay espacio para mejorar.', name: 'Sofía R.' }
  ]);




const currentIndex = ref(0);

const isFirstReview = ref(currentIndex.value - 1 < 0);
const isLastReview = ref(currentIndex.value + 1 > reviews.value.length - 1);

const setSliderTransform = computed(() => {
  return {
    '--currentIndex': currentIndex.value
  };
});

const averageRating = computed(() => {
  const total = reviews.value.reduce((sum, review) => sum + review.rating, 0);
  return (total / reviews.value.length).toFixed(1);
});

const startDrag = (event) => {
  isDragging = true;
  startX = event.touches ? event.touches[0].clientX : event.clientX;
};

const onDrag = (event) => {
  if (!isDragging) return;
  const currentX = event.touches ? event.touches[0].clientX : event.clientX;
  const difference = startX - currentX;

  if (Math.abs(difference) > 50) {
    if (difference > 0) {
      nextReview();
    } else {
      prevReview();
    }
    isDragging = false;
  }
};

const endDrag = () => {
  isDragging = false;
};

const nextReview = () => {
  currentIndex.value = (currentIndex.value + 1) % reviews.value.length;
  isFirstReview.value = currentIndex.value - 1 < 0;
  isLastReview.value = currentIndex.value + 1 > reviews.value.length - 1;
};

const prevReview = () => {
  currentIndex.value = (currentIndex.value - 1 + reviews.value.length) % reviews.value.length;
  isFirstReview.value = currentIndex.value - 1 < 0;
  isLastReview.value = currentIndex.value + 1 > reviews.value.length - 1;
};
</script>

<style scoped>
.slider {
  transition: transform 0.3s ease-in-out;
  transform: translateX(calc(-50% * var(--currentIndex))); /* Para pantallas grandes por defecto */
}

/* Para pantallas pequeñas */
@media (max-width: 767px) {
  .slider {
    transform: translateX(calc(-100% * var(--currentIndex))); /* Para pantallas pequeñas */
  }
}

@media (min-width: 768px) {
    .md\:text-6xl {
        font-size: 3.75rem /* 60px */;
        line-height: 1;
    }
}

.text-outlined {
  -webkit-text-stroke: 0.5px rgba(0, 0, 0, 1);
}
</style>
