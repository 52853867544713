<template>
  <div class="flex flex-col items-center gap-10 pt-28 xs:pt-40 pb-20 px-8 lg:px-28 2xl:px-64 min-h-screen animated-block-y">
    <h1 class="font-bold text-4xl sm:text-5xl xl:text-6xl text-center">Política de Privacidad</h1>
    <div class="flex flex-col gap-6 text-xl">
      <p>
        En TuParty, valoramos y respetamos tu privacidad. Esta política de privacidad explica cómo recopilamos, usamos, divulgamos y protegemos tu información personal cuando utilizas nuestra aplicación o servicios.
      </p>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">1. Información que Recopilamos</h3>
        <p class="pl-4">
          Recopilamos varios tipos de información para proporcionar y mejorar nuestro servicio:
        </p>
        <p class="pl-4">
          <strong>a. Información proporcionada directamente:</strong> Esto incluye tu nombre, dirección de correo electrónico, información de contacto y otros detalles que nos das al registrarte o utilizar la aplicación.
        </p>
        <p class="pl-4">
          <strong>b. Información recopilada automáticamente:</strong> Datos sobre tu dispositivo, ubicación, uso de la aplicación, y cookies que se utilizan para mejorar la experiencia del usuario.
        </p>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">2. Uso de la Información</h3>
        <p class="pl-4">
          Utilizamos tu información para operar, mantener y mejorar la aplicación. Esto incluye:
        </p>
        <ul class="list-disc pl-12">
          <li>Proveer acceso y uso de las funcionalidades de la aplicación.</li>
          <li>Personalizar la experiencia del usuario.</li>
          <li>Comunicarnos contigo en relación a actualizaciones y cambios importantes.</li>
          <li>Analizar el uso de la aplicación para mejorar la funcionalidad.</li>
        </ul>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">3. Compartir de Información</h3>
        <p class="pl-4">
          No compartimos tu información personal con terceros, excepto en los siguientes casos:
        </p>
        <ul class="list-disc pl-12">
          <li>Cuando sea requerido por ley.</li>
          <li>Para proteger los derechos, la propiedad y la seguridad de la empresa y sus usuarios.</li>
          <li>Si es necesario para proporcionar servicios, como a proveedores de servicios externos.</li>
        </ul>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">4. Protección de la Información</h3>
        <p class="pl-4">
          Implementamos medidas de seguridad para proteger tu información personal frente a accesos no autorizados, alteraciones o divulgaciones. Sin embargo, ninguna transmisión de datos por internet es completamente segura, por lo que no podemos garantizar la seguridad absoluta.
        </p>
      </div>

      <div class="space-y-1">
        <h3 class="text-xl font-semibold">5. Cambios en esta Política</h3>
        <p class="pl-4">
          Nos reservamos el derecho de actualizar esta Política de Privacidad en cualquier momento. Notificaremos cualquier cambio publicando la nueva política en esta página. Es recomendable revisar esta página periódicamente para estar al tanto de las actualizaciones.
        </p>
      </div>

      <p>
        Si tienes alguna pregunta o inquietud sobre nuestra Política de Privacidad, no dudes en contactarnos a través de nuestro equipo de soporte.
      </p>
    </div>
  </div>
</template>

<script setup>
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();
</script>