<template>
    <div class="flex flex-col items-center gap-10 pt-28 xs:pt-40 pb-20 px-8 lg:px-28 min-h-screen animated-block-y">
        <h1 class="font-bold text-4xl sm:text-5xl xl:text-6xl text-center">Preguntas Frecuentes</h1>
        
        <!-- Selector de tipo de usuario -->
        <div class="grid grid-cols-1 xxs:grid-cols-2 md:grid-cols-4 gap-2 bg-tp-yellow p-2 rounded-2xl shadow-md transition-all duration-300 max-w-max">
            <button 
                v-for="(role, index) in roles" 
                :key="index" 
                @click="selectedRole = role.key"
                class="px-4 py-2 rounded-lg font-medium transition-all duration-300 text-center"
                :class="{
                    'bg-tp-black text-tp-yellow': selectedRole === role.key,
                    'bg-tp-yellow text-tp-black': selectedRole !== role.key
                }">
                {{ role.label }}
            </button>
        </div>

        
        <TransitionGroup name="fade" tag="div" class="flex flex-col gap-4 w-full">
            <QuestionComponent v-for="(faq, index) in filteredFAQs" :key="index" :question="faq.question" :answer="faq.answer" />
        </TransitionGroup>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import QuestionComponent from '@/components/FAQ/QuestionComponent.vue';
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();

const selectedRole = ref('user'); // Valor por defecto

const roles = [
    { key: 'user', label: 'Usuarios' },
    { key: 'dj', label: 'DJs' },
    { key: 'club', label: 'Discotecas' },
    { key: 'event', label: 'Eventos / Bodas' }
];

const faqs = {
    user: [
        { question: '¿Qué pasa si no suena mi canción?', answer: 'Si tu canción no suena, se te devolverá el importe completo en el mismo instante que termine la sesión.' },
        { question: '¿Es gratis usar la app o tengo que pagar para votar por canciones?', answer: 'Puedes usar la app de forma gratuita para ver qué se está votando. A parte, te regalamos varios votos gratis, pero para influir en la música, algunas votaciones pueden tener coste.' },
        { question: '¿Cómo sé si una discoteca está usando TuParty?', answer: 'Si una discoteca está usando TuParty, verás un código QR en el local que te permitirá acceder directamente a la votación.' },
        { question: '¿Hace falta descargarse una aplicación?', answer: 'No, ¡solo escaneando un QR estás dentro!' }
    ],
    dj: [
        { question: '¿Cómo me registro como DJ en TuParty?', answer: "Puedes registrarte en nuestra plataforma desde <a href='/dj#form' class='underline font-semibold'>aquí</a>." },
        { question: '¿Puedo decidir qué canciones entran en la votación o todo es libre?', answer: 'De momento no, pero permitimos rechazar cualquier canción para eliminar las que no consideres adecuadas.' },
        { question: '¿Qué beneficios tengo como DJ al usar TuParty?', answer: 'Además de conocer los gusto musicales de tu público, puedes obtener ingresos adicionales con las votaciones de los usuarios.' },
        { question: '¿Cómo se evitan los abusos o trolls en la votación?', answer: 'Como hemos mencionado antes, TuParty permite rechazar las canciones que no consideres adecuadas.' }
    ],
    club: [
        { question: '¿Cómo me beneficio económicamente de TuParty?', answer: 'Las discotecas reciben un porcentaje de los ingresos generados por la votación de canciones.' },
        { question: '¿Cómo puedo implementar TuParty en mi discoteca?', answer: "Solo necesitas <a href='/discoteca#form' class='underline font-semibold'>registrar</a> tu local en nuestra plataforma y te proporcionamos todo lo necesario para integrarlo en tu sala." },
        { question: '¿Se pueden personalizar las reglas de votación?', answer: 'Sí, puedes establecer tiempos de votación, límites de canciones y restricciones de géneros musicales.' },
        { question: '¿Puedo ver estadísticas de las noches en las que se ha usado TuParty?', answer: 'Sí, ofrecemos un dashboard para ver qué canciones se han aceptado y cuáles se han rechazado, junto a su facturación' }
    ],
    event: [
        { question: '¿Puedo usar TuParty en un evento privado?', answer: "Sí, puedes activar TuParty en eventos privados y personalizar las votaciones según tu necesidad. <a href='/eventos#form' class='underline font-semibold'>Contáctanos</a> para empezar" },
        { question: '¿Necesito contratar un DJ que use TuParty o cualquier DJ puede adaptarse?', answer: 'Cualquier DJ puede adaptarse a TuParty. No es necesario un DJ específico.' },
        { question: '¿Cómo gestiono la participación del público en la votación?', answer: 'La participación del público es sencilla, solo tienen que escanear un QR o acceder a través de un link y votar por las canciones que quieran escuchar.' },
        { question: '¿TuParty funciona en cualquier tipo de evento, como bodas o festivales?', answer: 'Sí, TuParty se adapta a diferentes tipos de eventos, como bodas, festivales, y cualquier tipo de fiesta que desees organizar.' }
    ]
};

const filteredFAQs = computed(() => faqs[selectedRole.value]);
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
.fade-enter-from, .fade-leave-to {
    opacity: 0;
    transform: translateY(10px);
}

@media (min-width: 768px) {
    .md\:grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
</style>
