<template>
  <div class="pt-24 xs:pt-32">
    <InfiniteBanner :isDJ="true" />
    <MixTableSection :isDJ="true" />
    <DJBenefitsSection :isDJ="true" />
    <DJSteps :isDJ="true" />
    <ImagesCarrousel />
    <div class="px-8 lg:px-28">
      <ReviewsSection type="dj" />
    </div>
  </div>
</template>

<script setup>
import InfiniteBanner from '@/components/DJ/InfiniteBanner.vue'
import MixTableSection from '@/components/DJ/MixTableSection.vue'
import DJBenefitsSection from '@/components/DJ/DJBenefitsSection.vue'
import DJSteps from '@/components/DJ/DJSteps.vue'
import { useAnimationObserver } from '@/composables/useAnimationObserver';
import ImagesCarrousel from '@/components/DJ/ImagesCarrousel.vue';
import ReviewsSection from '@/components/DJ/ReviewsSection.vue';

useAnimationObserver();

</script>
