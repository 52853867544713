<template>
  <div class="flex flex-col items-center border-2 border-tp-yellow rounded-2xl p-2 sm:p-4 animated-block-y animated-number">
    <transition name="slide-fade" mode="out-in">
      <span v-if="dataType != 'Beneficio'" class="font-bold text-3xl italic" :key="displayNumber">{{ displayNumber }} +</span>
      <span v-else class="font-bold text-3xl italic">{{ displayNumber }} %</span>
    </transition>
    <span class="font-medium">{{ dataType }}</span>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue';

const props = defineProps({
  targetNumber: Number,
  dataType: String,
  isVisible: Boolean, 
});

const displayNumber = ref(0);

const animateCount = () => {
  let startTimestamp = null;

  const step = (timestamp) => {
    if (!startTimestamp) startTimestamp = timestamp;
    const progress = timestamp - startTimestamp;

    displayNumber.value = Math.min(
      Math.floor((progress / 2000) * props.targetNumber), 
      props.targetNumber
    );

    if (displayNumber.value < props.targetNumber) {
      requestAnimationFrame(step);
    }
  };

  requestAnimationFrame(step);
};

watch(() => props.isVisible, (newValue) => {
  if (newValue) {
    animateCount();
  }
});
</script>

