<template>
    <div class="flex flex-col items-center gap-12 px-8 lg:px-28 sm:mt-16">
        <h1 class="font-bold text-center text-4xl sm:text-5xl xl:text-6xl animated-block-y">Beneficios de usar TuParty</h1>
        <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 max-w-3xl">
            <div class="flex flex-col gap-4">
                <DJBenefitCard :title="title1" :description="description1" :image="image1" />
                <DJBenefitCard :title="title3" :description="description3" :image="image3" />
            </div>
            <div class="flex flex-col gap-4 sm:pt-16">
                <DJBenefitCard :title="title2" :description="description2" :image="image2" />
                <DJBenefitCard :title="title4" :description="description4" :image="image4" />
            </div>
            
        </div>
    </div>
</template>

<script setup>
import DJBenefitCard from '@/components/DJ/DJBenefitCard.vue'
import { defineProps } from 'vue';

const props = defineProps({
  isDJ: Boolean,
});

const title1 = props.isDJ ? 'Monetiza tus sesiones' : 'Monetiza la música'
const description1 =  props.isDJ ? 'El público quiere participar, tú cobras por hacerlo posible.' : 'Gana dinero extra con cada voto sin aumentar costos ni cambiar tu modelo de negocio.'
const image1 =  props.isDJ ? '<lord-icon src="https://cdn.lordicon.com/gjjvytyq.json" trigger="loop" delay="1500" state="in-wallet" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>' : '<lord-icon src="https://cdn.lordicon.com/ncitidvz.json" trigger="loop" delay="1500" state="in-thumbs" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

const title2 = props.isDJ ? 'Sin interrupciones' : 'Más consumo en barra'
const description2 = props.isDJ ? 'Olvídate de la gente que viene a tu mesa para decirte como tienes que hacer tu trabajo.' : 'Mejor música, más tiempo en la discoteca, más ventas.'
const image2 = props.isDJ ? '<lord-icon src="https://cdn.lordicon.com/zxvuvcnc.json" trigger="loop" delay="1600" state="in-cross" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>' : '<lord-icon src="https://cdn.lordicon.com/qhkvfxpn.json" trigger="loop" delay="1500" state="in-thumbs" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

const title3 = props.isDJ ? 'Control total' : 'Haz que vuelvan'
const description3 = props.isDJ ? 'Si no tienes alguna canción o no encaja con la fiesta, puedes rechazarla.' : 'A la gente le gusta poder elegir la música, y eso los hace querer repetir.'
const image3 = props.isDJ ? '<lord-icon src="https://cdn.lordicon.com/hbzwsetw.json" trigger="loop" delay="1500" state="in-thumbs" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>' : '<lord-icon src="https://cdn.lordicon.com/ogkflacg.json" trigger="loop" delay="1500" state="in-thumbs" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

const title4 = props.isDJ ? 'Acierta siempre con tu música' : 'Haz que tu DJ acierte siempre'
const description4 = props.isDJ ? 'Con TuParty, sabrás exactamente qué quiere escuchar el público.' : 'Con TuParty, el DJ pondrá la música que el público realmente quiere.'
const image4 = props.isDJ ? '<lord-icon src="https://cdn.lordicon.com/lomfljuq.json" trigger="loop" delay="1500" state="in-build" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>' : '<lord-icon src="https://cdn.lordicon.com/lomfljuq.json" trigger="loop" delay="1500" state="in-thumbs" colors="primary:#F9F871" style="width:80px;height:80px;filter: drop-shadow(0 0 2px #0D0D0D);"></lord-icon>'

</script>

