<template>
    <div class="mix-table-section flex flex-col items-center gap-4 pt-10 pb-20 px-8 lg:px-28 animated-block-y">
        <div class="flex justify-center relative mt-6 sm:mt-10 md:mt-14 lg:mt-20 xl:mt-28 2xl:mt-48">
            <div class="gradient-bg"></div>
            <div class="absolute  sm:-top-5 md:-top-12 xl:-top-24 2xl:-top-40">
                <div class="dj-animated-text-container">
                    <div v-if="isDJ" class="dj-animated-text-header">
                        <div class="header concat">
                            <h1>Conecta</h1>
                            <div class="header-wrapper"></div>
                        </div>
                        <div class="header concat">
                            <h1 class="pl-2" style="line-height: 105%;"><span class="italic normal-case">con </span>tu público<span class="italic normal-case pr-2"> y</span></h1>
                            <div class="header-wrapper"></div>
                        </div>
                        <div class="header concat">
                            <h1>cobra <span class="italic normal-case">por ello</span> </h1>
                            <div class="header-wrapper"></div>
                        </div>
                    </div>
                    <div v-else class="dj-animated-text-header">
                        <div class="header concat">
                            <h1 style="line-height: 100%;">Súmate <span class="italic normal-case pr-2">al</span></h1>
                            <div class="header-wrapper"></div>
                        </div>
                        <div class="header concat">
                            <h1>futuro <span class="italic normal-case pr-2">de</span></h1>
                            <div class="header-wrapper"></div>
                        </div>
                        <div class="header concat">
                            <h1><span class="italic normal-case pr-2"> la</span> fiesta</h1>
                            <div class="header-wrapper"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dj-table-container z-10">
                <img class="hidden sm:block max-w-xl md:max-w-2xl lg:max-w-4xl" :src="djTable" alt="Photo of a DJ table">
                <img class="sm:hidden max-w-xs xs:max-w-sm transform -translate-y-7" :src="djTableXs" alt="Photo of a smaller DJ table">
            </div>
            <a href="#form" class="absolute bottom-14 xs:bottom-16 sm:bottom-5 lg:bottom-14 z-20">
              <button class="discoteca">
                  <span class="circle" aria-hidden="true">
                  <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">Empieza ya</span>
              </button>
            </a>
        </div>
    </div>
</template>

<script setup>
import djTable from '@/assets/images/dj-table.png'
import djTableXs from '@/assets/images/dj-table-xs.png'
import { onMounted, ref } from 'vue';
import { gsap } from 'gsap';
import { defineProps } from 'vue';

defineProps({
  isDJ: Boolean,
});

const isVisible = ref(false);

onMounted(() => {
  const element = document.querySelector('.animated-block-y');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        isVisible.value = true;
        observer.unobserve(entry.target);
        animate(); 
      }
    });
  });

  observer.observe(element);

  const animate = () => {
    const tl = gsap.timeline();

    tl.from(".dj-table-container", {
      duration: 1.5,
      opacity: 0,
      y: 50,
      ease: "power3.out",
    });

    tl.to(".dj-animated-text-header .header > h1", {
      duration: 2,
      opacity: 1,
      top: 0,
      ease: "power3.inOut",
      stagger: {
        amount: 0.3
      },
    }, "-=1.5");

    tl.from(".gradient-bg", {
      duration: 5,
      opacity: 0,
      ease: "power3.out",
    }, "-=1");
  };
});
</script>

<style scoped>
@media (min-width: 640px) {
    .sm\:bottom-5 {
        bottom: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .lg\:bottom-14 {
        bottom: 3.5rem;
    }
}

.gradient-bg {
    background: radial-gradient(50% 49.99999999999999% at 50% 49.99999999999999%, #ffffff 0%, rgba(156, 156, 251, 0.8) 24.285714285714285%, rgba(113, 114, 249, 0.4) 45%, rgba(36, 36, 79, 0.4) 74.64285714285714%, rgba(0, 18, 41, 0) 100%);
    height: 700px;
    width: 800px;
    position: absolute;
    transform: translateY(-13%);
    overflow: hidden;
}

@media (max-width: 1024px) {
    .gradient-bg {
        height: 500px;
        width: 600px;
        transform: translateY(-10%);
    }
}

@media (max-width: 768px) {
    .gradient-bg {
        height: 400px;
        width: 500px;
        transform: translateY(-10%);
    }
}

@media (max-width: 640px) {
    .gradient-bg {
        height: 350px;
        width: 350px;
        transform: translateY(-10%);
    }
}

.dj-animated-text {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.dj-animated-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dj-animated-text-container .header {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.concat {
    display: flex;
}

.header h1 {
    position: relative;
    top: 125px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 6vw;
    line-height: 90%;
    color: #F9F871;
    overflow: hidden; 
    padding-right: 10px;
    opacity: 0;
}

@media (max-width: 520px) {
  .header h1 {
    font-size: 4vh;
  }
}

.header-wrapper:after {
    content: "";
    position: absolute;
    width: 110%;
    height: 120px;
    top: 140px;
    left: -20px;
    margin: 0 auto;
}

button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.discoteca {
  width: 12rem;
  height: auto;
}

button.discoteca .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: #F9F871;
  border-radius: 1.625rem;
}

button.discoteca .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #F9F871;
}

button.discoteca .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.35rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.discoteca .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #0D0D0D;
  border-right: 0.125rem solid #0D0D0D;
  transform: rotate(45deg);
}

button.discoteca .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  padding: 0.5rem 0;
  margin: 0 0 0 1.85rem;
  color: #F9F871;
  font-weight: 700;
  line-height: 1.6;
  text-align: left;
  text-transform: uppercase;
}

button.discoteca:hover .circle {
  width: 100%;
}

button.discoteca:hover .circle .icon.arrow {
  background: #0D0D0D;
  transform: translate(1rem, 0);
}

button.discoteca:hover .button-text {
  color: #0D0D0D;
}

</style>
    