<template>
    <div class="flex flex-col w-full overflow-hidden gap-1 pt-10">
        <div class="flex whitespace-nowrap gap-1">
            <div class="ticker-track flex gap-1">
                <UserBenefitCard :text1="text1" :text2="text2" />
                <UserBenefitCard :text1="text3" :text2="text4" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text5" :text2="text6" />
                <UserBenefitCard :text1="text7" :text2="text8" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text9" :text2="text10" />
                <UserBenefitCard :text1="text11" :text2="text12" />
                <UserBenefitCard :image="image1" />
            </div>
            <div class="ticker-track flex gap-1">
                <UserBenefitCard :text1="text1" :text2="text2" />
                <UserBenefitCard :text1="text3" :text2="text4" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text5" :text2="text6" />
                <UserBenefitCard :text1="text7" :text2="text8" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text9" :text2="text10" />
                <UserBenefitCard :text1="text11" :text2="text12" />
                <UserBenefitCard :image="image1" />
            </div>
        </div>
        <div class="flex whitespace-nowrap gap-1">
            <div class="ticker-track reverse flex gap-1">
                <UserBenefitCard :text1="text1" :text2="text2" />
                <UserBenefitCard :text1="text3" :text2="text4" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text5" :text2="text6" />
                <UserBenefitCard :text1="text7" :text2="text8" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text9" :text2="text10" />
                <UserBenefitCard :text1="text11" :text2="text12" />
                <UserBenefitCard :image="image1" />
            </div>
            <div class="ticker-track reverse flex gap-1">
                <UserBenefitCard :text1="text1" :text2="text2" />
                <UserBenefitCard :text1="text3" :text2="text4" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text5" :text2="text6" />
                <UserBenefitCard :text1="text7" :text2="text8" />
                <UserBenefitCard :image="image1" />
                <UserBenefitCard :text1="text9" :text2="text10" />
                <UserBenefitCard :text1="text11" :text2="text12" />
                <UserBenefitCard :image="image1" />
            </div>
        </div>
    </div> 
</template>

<script setup>
import UserBenefitCard from '@/components/Features/UserBenefitCard.vue'

const text1 = "Participa en"
const text2 = "la playlist"

const text3 = "Sin descargar"
const text4 = "una app"

const text5 = "Haz match"
const text6 = "musical"

const text7 = "Disfruta de tu"
const text8 = "canción favorita"

const text9 = "Siente la emoción"
const text10 = "por ganar"

const text11 = "Tu música,"
const text12 = "TuParty"

const image1 = "https://www.wimpdecaf.com/cdn/shop/files/Kuma_idle_website_retina_2560x.gif?v=1719872034"
// const image2 = "https://www.wimpdecaf.com/cdn/shop/files/bean_walking_website_retina_2560x.gif?v=1720022986"

</script>

<style scoped>
.ticker-track {
    animation: scroll 30s linear infinite;
}

@keyframes scroll {
    0% {
    transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}

.ticker-track.reverse {
    animation-name: rscroll;
}

@keyframes rscroll {
    0% {
    transform: translate(-100%);
    }

    100% {
        transform: translate(0);
    }
}
</style>