<template>
    <div class="flex flex-col justify-center gap-6 px-8 lg:px-28 mt-16">
        <h1 class="font-bold text-4xl sm:text-5xl xl:text-6xl animated-block text-center">Nuestra visión</h1>
        <div class="flex justify-center items-center xs:gap-10 lg:gap-16 vision-container animated-block">
            <div ref="mockupContainer" class="flex justify-center items-center">
                <div
                    class="relative bg-black overflow-hidden border-4 border-gray-800 shadow-xl mockup-container"
                    style="border-radius: 36px;"
                >
                    <div class="absolute top-0 left-1/2 transform -translate-x-1/2 w-24 h-6 bg-gray-800 rounded-b-xl"></div>

                    <!-- Incrustación de YouTube en lugar de un video local -->
                    <iframe
                    ref="video"
                    class="w-full h-full object-cover"
                    :src="videoSrc"
                    frameborder="0"
                    allow="autoplay; encrypted-media"
                    allowfullscreen
                    ></iframe>
                </div>
            </div>
            <p class="text-tp-yellow text-lg sm:text-xl vision-text max-w-lg animated-block">
                <span class="hidden sm:block">En <span class="font-semibold">TuParty</span>, queremos hacer que cada vez más gente en las fiestas pueda <span class="font-semibold">disfrutar</span> de esas <span class="font-semibold">canciones</span> que les hacen dejarse la <span class="font-semibold">garganta</span>.</span> 
                <br>
                <span>Nuestra <span class="font-semibold">visión</span> es convertirnos en la plataforma de referencia para la elección de música. Soñamos con un mundo donde <span class="font-semibold">ninguna</span> fiesta sea <span class="font-semibold">aburrida</span> y donde cada noche sea <span class="font-semibold">única</span>.</span>
                <br><br>
                <span class="hidden lg:block">Cada vez vivimos más metidos en nuestros <span class="font-semibold">problemas</span> y nos cuesta más dejar de pensar en ellos. La <span class="font-semibold">música</span>, y sobre todo la que nos <span class="font-semibold">gusta</span> mucho, tiene esa <span class="font-semibold">magia</span> de ser capaz de hacernos <span class="font-semibold">olvidarnos</span> de ellos por un momento.</span>
            </p>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const videoSrc = "https://www.youtube.com/embed/KvQFerzXwCY?autoplay=1&mute=1&loop=1&vq=hd1080&modestbranding=1";  

const mockupContainer = ref(null);

onMounted(() => {
  const observer = new IntersectionObserver(([entry]) => {
    if (entry.isIntersecting && mockupContainer.value) {
      const iframe = mockupContainer.value.querySelector("iframe");
      // Reproducir el video cuando el contenedor esté visible
      iframe?.contentWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
    } else {
      const iframe = mockupContainer.value.querySelector("iframe");
      // Pausar el video cuando no está visible
      iframe?.contentWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
    }
  }, { threshold: 0.5 });

  if (mockupContainer.value) {
    observer.observe(mockupContainer.value);
  }

  onUnmounted(() => {
    observer.disconnect();
  });
});
</script>

<style scoped>
.mockup-container {
    width: 300px;
    height: 600px;
  }

@media (max-width: 768px) {
  .mockup-container {
    width: 250px;
    height: 500px;
  }
}

@media (max-width: 560px) {
  .vision-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .vision-text {
    text-align: center;
  }
}
</style>