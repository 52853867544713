<template>
  <PreLoader v-if="showPreLoader" />
  <HeroSection />
  <!-- <TextParallaxSection /> -->
  <DataSection />
  <FeaturesSection />
  <RedirectionSection />
  <VisionSection />
  <!-- <VideoMockup /> -->
  <ContactUs type="user" />
</template>

<script setup>
import { ref, onMounted } from 'vue';
import PreLoader from '@/components/Main/PreLoader.vue'
import ContactUs from '@/components/Common/ContactUs.vue'
import HeroSection from '@/components/Main/HeroSection.vue'
// import TextParallaxSection from '@/components/Main/TextParallaxSection.vue'
import DataSection from '@/components/Main/DataSection.vue'
import FeaturesSection from '@/components/Main/FeaturesSection.vue'
import RedirectionSection from '@/components/Main/RedirectionSection.vue'
import VisionSection from '@/components/Main/VisionSection.vue'
// import VideoMockup from '@/components/Main/VideoMockup.vue'
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();

const showPreLoader = ref(false);

onMounted(() => {
  const navigationEntries = window.performance.getEntriesByType("navigation");
  const isReload = navigationEntries.length > 0 && navigationEntries[0].type === "reload";

  if (isReload || !sessionStorage.getItem('preLoaderShown')) {
    showPreLoader.value = true;
    sessionStorage.setItem('preLoaderShown', 'true');
  }
});

</script>
