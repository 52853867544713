import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/views/MainPage.vue';
import DiscotecaPage from "@/views/DiscotecaPage.vue";
import DJPage from "@/views/DJPage.vue";
import EventsPage from "@/views/EventsPage.vue";
import FAQ from '@/views/FAQ.vue';
import AboutUs from "@/views/AboutUs.vue";
import TermsConditions from "@/views/TermsConditions.vue";
import PrivacyPolicy from "@/views/PrivacyPolicy.vue";

const routes = [
  {
    path: "/",
    name: "MainPage",
    component: MainPage,
  },
  {
    path: "/discoteca",
    name: "DiscotecaPage",
    component: DiscotecaPage,
  },
  {
    path: "/dj",
    name: "DJPage",
    component: DJPage,
  },
  {
    path: "/eventos",
    name: "EventsPage",
    component: EventsPage,
  },
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
  },
  {
    path: "/about",
    name: "AboutUs",
    component: AboutUs,
  },
  {
    path: "/terms",
    name: "TermsConditions",
    component: TermsConditions,
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: PrivacyPolicy,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
