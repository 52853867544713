<template>
    <div class="flex flex-col items-center gap-6 px-8 lg:px-28 mt-16">
        <h1 class="font-bold text-4xl sm:text-5xl xl:text-6xl animated-block text-center sm:text-left">¿Qué eres?</h1>
        <div class="flex flex-col xs:flex-row lg:items-start gap-2 font-bold text-base lg:text-lg">
            <a href="/discoteca">
                <button class="discoteca animated-block redirection-button">
                    <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                    </span>
                    <span class="button-text">Discoteca</span>
                </button>
            </a>
            <a href="/dj">
                <button class="dj animated-block redirection-button">
                    <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                    </span>
                    <span class="button-text">DJ</span>
                </button>
            <a href="/evento">
                <button class="evento animated-block redirection-button">
                    <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                    </span>
                    <span class="button-text">Evento</span>
                </button>
            </a></a>

        </div>
    </div>
</template>

<style setup>
button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

button.discoteca {
  width: 12.5rem;
  height: auto;
}

button.discoteca .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: #F9F871;
  border-radius: 1.625rem;
}

button.discoteca .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #F9F871;
}

button.discoteca .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.35rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.discoteca .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #0D0D0D;
  border-right: 0.125rem solid #0D0D0D;
  transform: rotate(45deg);
}

button.discoteca .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  padding: 0.5rem 0;
  margin: 0 0 0 1.85rem;
  color: #F9F871;
  font-weight: 700;
  line-height: 1.6;
  text-align: left;
  text-transform: uppercase;
}

button.discoteca:hover .circle {
  width: 100%;
}

button.discoteca:hover .circle .icon.arrow {
  background: #0D0D0D;
  transform: translate(1rem, 0);
}

button.discoteca:hover .button-text {
  color: #0D0D0D;
}

button.dj {
  width: 8rem;
  height: auto;
}

button.dj .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: #F9F871;
  border-radius: 1.625rem;
}

button.dj .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #F9F871;
}

button.dj .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.35rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.dj .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #0D0D0D;
  border-right: 0.125rem solid #0D0D0D;
  transform: rotate(45deg);
}

button.dj .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  padding: 0.5rem 0;
  margin: 0 0 0 1.85rem;
  color: #F9F871;
  font-weight: 700;
  line-height: 1.6;
  text-align: left;
  text-transform: uppercase;
}

button.dj:hover .circle {
  width: 100%;
}

button.dj:hover .circle .icon.arrow {
  background: #0D0D0D;
  transform: translate(1rem, 0);
}

button.dj:hover .button-text {
  color: #0D0D0D;
}


button.evento {
  width: 10.5rem;
  height: auto;
}

button.evento .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 2.5rem;
  height: 2.5rem;
  background: #F9F871;
  border-radius: 1.625rem;
}

button.evento .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #F9F871;
}

button.evento .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.35rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.evento .circle .icon.arrow::before {
  position: absolute;
  content: '';
  top: -0.25rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #0D0D0D;
  border-right: 0.125rem solid #0D0D0D;
  transform: rotate(45deg);
}

button.evento .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  padding: 0.5rem 0;
  margin: 0 0 0 1.85rem;
  color: #F9F871;
  font-weight: 700;
  line-height: 1.6;
  text-align: left;
  text-transform: uppercase;
}

button.evento:hover .circle {
  width: 100%;
}

button.evento:hover .circle .icon.arrow {
  background: #0D0D0D;
  transform: translate(1rem, 0);
}

button.evento:hover .button-text {
  color: #0D0D0D;
}

</style>