<template>
  <nav :class="navbarClasses" style="width: calc(100% - 3rem);">
    <div class="flex justify-center lg:justify-between items-center">
      <a href="/" class="font-bold text-3xl xs:text-4xl" :class="textColorClass">TuParty</a>
      <div class="hidden lg:flex font-semibold lg:gap-10 gap-6 xl:absolute xl:left-1/2 xl:transform xl:-translate-x-1/2 uppercase">
        <a class="hover:opacity-80" href="/">Intro</a>
        <a class="hover:opacity-80" href="/discoteca">Discotecas</a>
        <a class="hover:opacity-80" href="/dj">DJs</a>
        <a class="hover:opacity-80" href="/eventos">Eventos / Bodas</a>
      </div>
      <div class="flex lg:hidden absolute right-5 cursor-pointer" @click="toggleNav">
        <div class="relative flex justify-center items-center w-5 h-5 cursor-pointer rounded-full transition duration-300 ease-in-out hover:bg-tp-yellow hover:bg-opacity-20" :class="{'bg-tp-yellow bg-opacity-20': navOpen}" style="padding: 1.2em;">
            <span class="top-line absolute w-6 h-0.5 m-auto rounded-lg transform rotate-0 transition-all duration-500" :class="[{'spin': navOpen}, lineClasses]"></span>
            <span class="bottom-line absolute w-6 h-0.5 m-auto rounded-lg transform rotate-0 transition-all duration-500" :class="[{'spin': navOpen}, lineClasses]"></span>
        </div>
      </div>
      <a href="#form" :class="buttonClasses">
        ¡Empieza ya!
      </a>
      <div class="fixed left-0 w-full h-screen overflow-hidden bg-tp-black " style="z-index: -2; top: calc(-100% - 100px);  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);" :style="{ top: navOpen ? '0px' : 'calc(-100% - 100px)', transaitionDelay: navOpen ? '0s' : '0s' }">
        <ul class="nav-links">
          <li class="nav-item">
              <a href="/" @click="toggleNav" :style="{ top: navOpen ? '0px' : '120px', transitionDelay: navOpen ? '0.5s' : '0s' }">Intro</a>
              <div class="nav-item-wrapper"></div>
          </li>
          <li class="nav-item">
              <a href="/discoteca" @click="toggleNav" :style="{ top: navOpen ? '0px' : '120px', transitionDelay: navOpen ? '0.6s' : '0s' }">Discotecas</a>
              <div class="nav-item-wrapper"></div>
          </li>
          <li class="nav-item">
              <a href="/dj" @click="toggleNav" :style="{ top: navOpen ? '0px' : '120px', transitionDelay: navOpen ? '0.7s' : '0s' }">DJs</a>
              <div class="nav-item-wrapper"></div>
          </li>
          <li class="nav-item">
              <a href="/eventos" @click="toggleNav" :style="{ top: navOpen ? '0px' : '120px', transitionDelay: navOpen ? '0.8s' : '0s' }">Eventos / Bodas</a>
              <div class="nav-item-wrapper"></div>
          </li>
          <li class="nav-item">
              <a  href="#form" @click="toggleNav" :style="{ top: navOpen ? '0px' : '120px', transitionDelay: navOpen ? '0.9s' : '0s' }">¡Empieza ya!</a>
              <div class="nav-item-wrapper"></div>
          </li>
        </ul>
        <div class="nav-footer">
          <div class="nav-social-media">
            <ul>
              <li>
                  <a href="https://www.instagram.com/tuparty_club/" target="_blank" class="flex items-center gap-1" :style="{ bottom: navOpen ? '0px' : '-20px', opacity: navOpen ? '1' : '0', transitionDelay: navOpen ? '1s' : '0s' }"><img class="h-6 w-6" :src="instagram" alt="Instagram icon"></a>
              </li>
              <li>
                  <a href="https://www.tiktok.com/@tuparty" target="_blank" class="flex items-center gap-1" :style="{ bottom: navOpen ? '0px' : '-20px', opacity: navOpen ? '1' : '0', transitionDelay: navOpen ? '1.1s' : '0s' }"><img class="h-6 w-6" :src="tiktok" alt="Tiktok icon"></a>
              </li>
              <li>
                  <a href="https://www.linkedin.com/company/tuparty" target="_blank" class="flex items-center gap-1" :style="{ bottom: navOpen ? '0px' : '-20px', opacity: navOpen ? '1' : '0', transitionDelay: navOpen ? '1.2s' : '0s' }"><img class="h-6 w-6" :src="linkedin" alt="Linkedin icon"></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import linkedin from '@/assets/images/linkedin.png';
import instagram from '@/assets/images/instagram.png';
import tiktok from '@/assets/images/tiktok.png';

const router = useRouter();

const navOpen = ref(false);
const isScrolled = ref(false);
const transitionDelay = ref(0);

const toggleNav = () => {
    navOpen.value = !navOpen.value;
}

const handleScroll = () => {
  const navbar = document.querySelector('nav');

  if (!navbar) return;

  if (router.currentRoute.value.name === 'MainPage') {
    const heroSection = document.querySelector('.hero-section');
    if (!heroSection) return;
    isScrolled.value = heroSection.getBoundingClientRect().bottom <= navbar.getBoundingClientRect().bottom;
  } else if (router.currentRoute.value.name === 'DJPage' || router.currentRoute.value.name === 'DiscotecaPage') {
    const mixTableSection = document.querySelector('.mix-table-section');
    if (!mixTableSection) return;
    isScrolled.value = mixTableSection.getBoundingClientRect().bottom <= navbar.getBoundingClientRect().bottom;
  } else if (router.currentRoute.value.name === 'EventsPage') {
    const weOfferSection = document.querySelector('.we-offer-section');
    if (!weOfferSection) return;
    isScrolled.value = weOfferSection.getBoundingClientRect().bottom <= navbar.getBoundingClientRect().bottom;
  }
};

const navbarClasses = computed(() => ({
  'fixed rounded-xl mx-6 my-3 py-3 xs:py-6 lg:px-20 px-8 border-4 transition-colors duration-300 z-20': true,
  'bg-tp-black text-tp-yellow border-tp-yellow': !isScrolled.value,
  'bg-tp-yellow text-tp-black border-tp-black': isScrolled.value,
}));

const buttonClasses = computed(() => ({
  'lg:flex hidden font-semibold px-4 py-2 rounded-lg transition-colors duration-300 hover:bg-opacity-80': true,
  'bg-tp-yellow text-tp-black yellow-shadow': !isScrolled.value,
  'bg-tp-black text-tp-yellow shadow-xl': isScrolled.value,
}));

const textColorClass = computed(() => {
  let colorClass = 'text-tp-yellow transition-colors duration-500';
  if (isScrolled.value) {
    if (navOpen.value) {
      colorClass = 'text-tp-yellow transition-colors duration-500';
    } else {
      colorClass = `text-tp-black transition-colors duration-500 ${transitionDelay.value ? 'delay-500' : ''}`;
    }
  }
  return colorClass;
});

const lineClasses = computed(() => {
  let colorClass = 'bg-tp-yellow';
  if (isScrolled.value) {
    if (navOpen.value) {
      colorClass = 'bg-tp-yellow';
    } else {
      colorClass = `bg-tp-black ${transitionDelay.value ? 'delay-500' : ''}`;
    }
  }
  return colorClass;
});

watch(navOpen, (newNavOpen, oldNavOpen) => {
  if (oldNavOpen === true && newNavOpen === false) {
    transitionDelay.value = 1;
    setTimeout(() => {
      transitionDelay.value = 0;
    }, 500);
  }
});

watch(() => router.currentRoute.value.name, () => {
  setTimeout(handleScroll, 300);
});

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
  handleScroll();
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style scoped>

.top-line {
  top: 1.3em;
}

.bottom-line {
  bottom: 1.3em;
}

.top-line.spin {
  top: 1.2em;
  transform: rotate(135deg);
  background: rgba(249, 248, 113, 1);
}

.bottom-line.spin {
  bottom: 1.1em;
  transform: rotate(225deg);
  background: rgba(249, 248, 113, 1);
}

ul.nav-links {
  position: absolute;
  width: 100%;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

ul.nav-links li {
  list-style: none;
  position: relative;
}

ul.nav-links li a {
  text-decoration: none;
  color: #F9F871;
  font-size: 2em;
  font-weight: 600;
  position: relative;
  top: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}

.nav-item-wrapper::after {
  content: '';
  position: absolute;
  top: 120px;
  left: -10%;
  width: 120%;
  height: 100%;
  background: #0D0D0D;
  margin: 0 auto;
}

.nav-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 6em 2em;
  display: flex;
  justify-content: space-around;
}

.nav-social-media ul {
  display: flex;
  gap: 12px;
}

.nav-social-media ul li {
  list-style: none;
}

.nav-social-media ul li a {
  position: relative;
  bottom: -20px;
  text-decoration: none;
  color: #F9F871;
  font-weight: 600;
  opacity: 0;
  transition: all 2s cubic-bezier(0.16, 1, 0.3, 1);
}
</style>