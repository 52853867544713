<template>
    <div id="form" class="relative flex flex-col items-center gap-12 px-8 lg:px-28 pt-32 xs:pt-36 pb-20">

        <!-- Contenido -->
        <div class="flex flex-col items-center animated-block-y">
            <h1 class="font-bold text-center text-4xl sm:text-5xl xl:text-6xl pb-4 sm:pb-6 xl:pb-14 pointer-events-none z-10">¿Cómo empiezo?</h1>
            <div class="relative grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-center items-center gap-10 2xl:gap-20">
                <DJStep class="pointer-events-none z-10" :number="1" :text="text1" />
                <DJStep class="pointer-events-none z-10" :number="2" :text="text2" />
                <DJStep class="pointer-events-none z-10" :number="3" :text="text3" />
                <DJStep class="pointer-events-none z-10" :number="4" :text="text4" />

                <!-- Contenedor del video con hover y play -->
                <!-- <div 
                    class="absolute left-1/2 top-1/2 w-full sm:w-auto xl:w-1/4 h-auto transform -translate-x-1/2 -translate-y-1/2 transition-all  duration-300 ease-in-out rounded-xl overflow-hidden z-0 yellow-shadow"
                    :class="{ 'scale-110 yellow-shadow-expanded': isHovered }"
                    @mouseenter="isHovered = true"
                    @mouseleave="isHovered = false"
                    @click="openVideo"
                >
                    <video ref="backgroundVideo" class="w-full h-full object-cover rounded-xl" muted loop>
                        <source :src="djVideo" type="video/mp4" />
                    </video>

                    <div 
                        class="absolute inset-0 flex items-center justify-center transition-transform duration-300 ease-in-out"
                    >
                        <div class="relative w-10 h-10 bg-tp-yellow rounded-full flex items-center justify-center transition-all duration-300 ease-in-out" 
                            :class="{ 'w-12 h-12': isHovered }">
                            <svg class="absolute w-4 h-4 text-black" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M8 5v14l11-7z"></path>
                            </svg>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="flex flex-col items-center justify-center w-full pt-6 px-4 xs:px-12 animated-block-y">
                <a href="https://calendar.app.google/7nDdZeioDK9TySWXA" target='_blank' class="bg-tp-yellow text-tp-black font-semibold px-4 py-2 rounded-lg hover:bg-opacity-80 yellow-shadow uppercase">
                Empieza ya
                </a>
            </div>
        </div>

        <!-- Overlay de video más grande y fondo más oscuro -->
        <!-- <div v-if="isVideoOpen" class="fixed inset-0 flex items-center justify-center bg-tp-black bg-opacity-50 z-50" @click="closeVideo">
            <div class="relative w-4/5 sm:w-3/4 lg:w-2/3 xl:w-1/2" @click.stop>
                <video ref="overlayVideo" class="w-full h-auto rounded-xl shadow-lg" controls>
                    <source :src="djVideo" type="video/mp4" />
                </video>
            </div>
        </div> -->
    </div>
</template>

<script setup>
import { defineProps } from 'vue';
import DJStep from '@/components/DJ/DJStep.vue';
// import djVideoPath from '@/assets/videos/dj.mp4';

// const djVideo = djVideoPath;

const props = defineProps({
    isDJ: Boolean,
});

const text1 = props.isDJ ? "<a href='https://calendar.app.google/7nDdZeioDK9TySWXA' target='_blank' class='underline' style='pointer-events: auto;'>Reserva</a><br />un hueco para<br /> explicarte" : "<a href='https://calendar.app.google/7nDdZeioDK9TySWXA' target='_blank' class='underline' style='pointer-events: auto;'>Reserva</a><br />un hueco para<br /> explicarte";
const text2 = props.isDJ ? "Coloca<br />los QRs en<br />la sala" : "Coloca<br />los QRs en<br /> tu sala";
const text3 = props.isDJ ? "Empieza a<br />disfrutar de<br />las ventajas" : "Empieza a<br />disfrutar de<br />las ventajas";
const text4 = props.isDJ ? "Retira<br />tus ganancias" : "Recibe<br /> tu comisión";

// const isHovered = ref(false);
// const isVideoOpen = ref(false);
// const overlayVideo = ref(null);

// const openVideo = () => {
//     isVideoOpen.value = true;
//     setTimeout(() => {
//         overlayVideo.value.play();
//     }, 100);
// };

// const closeVideo = () => {
//     overlayVideo.value.pause();
//     overlayVideo.value.currentTime = 0;
//     isVideoOpen.value = false;
// };
</script>

<style scoped>
.steps-container {
    pointer-events: none;
}

@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(249, 249, 113, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
}

.yellow-shadow {
  animation: pulse-shadow 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
