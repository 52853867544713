<template>
    <div class="website-content">
        <div class="pre-loader">
            <div class="pre-loader-container">
              <div class="pre-loader-header">
                <div class="header concat">
                    <h1>There is</h1>
                    <div class="header-wrapper"></div>
                </div>
                <div class="header concat">
                    <h1>a new way</h1>
                    <div class="header-wrapper"></div>
                </div>
                <div class="header concat">
                    <h1 data-text="to party">to party</h1>
                    &nbsp;&nbsp;
                    <h1>!</h1>
                    <div class="header-wrapper"></div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { gsap } from "gsap";
import { onMounted } from "vue";

onMounted(() => {
  const tl = gsap.timeline();

  tl.to(".header > h1", {
    duration: 2,
    top: 0,
    ease: "power3.inOut",
    stagger: {
      amount: 0.3,
    },
  });

  tl.to(".header > h1", {
    onStart: () => {
      document.querySelectorAll('.header > h1').forEach((el) => {
        el.classList.add('animate-before');
      });
    }
  }, "-=1.5"); 

  tl.to(".pre-loader", 1, {
    opacity: 0,
    display: "none",
    ease: "power2.inOut",
    delay: 0
  });
});
</script>

<style scoped>
.website-content {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
}

.pre-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #0D0D0D;
  color: #F9F871;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.pre-loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pre-loader-container .header {
  position: relative;
  width: max-content;
  margin: 0 auto;
}

.concat {
    display: flex;
}

.header h1 {
    position: relative;
    top: 125px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    font-size: 6vw;
    line-height: 90%;
    color: #0D0D0D;
    -webkit-text-stroke: 1px rgba(249, 248, 113, 0.8);
    overflow: hidden; 
}

@media (max-width: 520px) {
  .header h1 {
    font-size: 5vh;
  }
}

.header h1::before {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    color: #F9F871;
    width: 0%;
    white-space: nowrap;
    overflow: hidden;
    transition: width 1s cubic-bezier(0.6, 0, 0.4, 1);
}

.header h1.animate-before::before {
    width: 100%;
}

.header-wrapper:after {
    content: "";
    position: absolute;
    width: 140%;
    height: 120px;
    top: 120px;
    left: -20px;
    background-color: #0D0D0D;
    margin: 0 auto;
}
</style>