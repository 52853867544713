<template>
  <div>
    <main class="min-h-screen pt-24 xs:pt-32">
      <!-- What We Offer Section -->
      <section class="py-10 we-offer-section">
        <div class="mx-auto px-4 xs:px-12">
          <div class="text-center mb-8 sm:mb-12 animated-block-y">
            <h2 class="font-bold text-4xl sm:text-5xl xl:text-6xl mb-4">Qué ofrecemos</h2>
            <p class="font-medium text-xl text-tp-yellow max-w-2xl mx-auto px-6">
              Un evento inolvidable. <br> Con TuParty, la música se adapta al momento y al público.
            </p>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div class="border-2 border-tp-yellow shadow-lg hover:shadow-xl transition-shadow rounded-2xl p-6 animated-block event-benefit-card">
              <div class="pt-6 text-center">
                <div class="mb-4 bg-tp-yellow w-16 h-16 rounded-full flex items-center justify-center mx-auto">
                  <lord-icon src="https://cdn.lordicon.com/lomfljuq.json" trigger="loop" delay="1500" state="" colors="primary:#0D0D0D" style="width:2rem;height:2rem;"></lord-icon>
                </div>
                <h3 class="text-xl font-bold mb-2">Acierta siempre con la música</h3>
                <p class="text-tp-yellow">
                  Los invitados eligen las canciones que quieren escuchar, asegurando que el DJ acierte siempre con la música.
                </p>
              </div>
            </div>

            <div class="border-2 border-tp-yellow shadow-lg hover:shadow-xl transition-shadow rounded-2xl p-6 animated-block event-benefit-card">
              <div class="pt-6 text-center">
                <div class="mb-4 bg-tp-yellow w-16 h-16 rounded-full flex items-center justify-center mx-auto">
                  <lord-icon src="https://cdn.lordicon.com/ozckswtv.json" trigger="loop" delay="1500" state="" colors="primary:#0D0D0D" style="width:2rem;height:2rem;"></lord-icon>
                </div>
                <h3 class="text-xl font-bold mb-2">Clientes más satisfechos</h3>
                <p class="text-tp-yellow">
                  Ofrece una experiencia única, mejora la satisfacción de tus clientes y aumenta las posibilidades de que te recomienden o repitan.
                </p>
              </div>
            </div>

            <div class="border-2 border-tp-yellow shadow-lg hover:shadow-xl transition-shadow rounded-2xl p-6 animated-block event-benefit-card">
              <div class="pt-6 text-center">
                <div class="mb-4 bg-tp-yellow w-16 h-16 rounded-full flex items-center justify-center mx-auto">
                  <lord-icon src="https://cdn.lordicon.com/lznlxwtc.json" trigger="loop" delay="1500" state="" colors="primary:#0D0D0D" style="width:2rem;height:2rem;"></lord-icon>
                </div>
                <h3 class="text-xl font-bold mb-2">Haz ruido</h3>
                <p class="text-tp-yellow">
                  Eventos con TuParty tienen más participación, más momentos virales y más buen rollo. Invitados felices, recomendaciones y nuevas contrataciones para ti.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- How It Works Section -->
      <section class="py-10">
        <div class="flex flex-col gap-4 itemsce mx-auto px-6 xs:px-12">
          <div class="text-center mb-12">
            <h2 class="font-bold text-4xl sm:text-5xl xl:text-6xl mb-4 animated-block-y">Cómo funciona</h2>
            <p class="text-xl font-medium text-tp-yellow max-w-2xl mx-auto animated-block-y">
              Descubre cómo integrar TuParty en tu evento en tres sencillos pasos.
            </p>
          </div>

          <div class="max-w-4xl mx-auto animated-block-y">
            <div class="relative">
              <!-- Vertical line -->
              <div class="absolute left-4 md:left-8 top-0 bottom-0 w-0.5 bg-purple-300 block"></div>
              
              <div class="space-y-12">
                <StepItem 
                  number="1" 
                  title="Contacto inicial" 
                  description="<div class='flex flex-col gap-2'><p><span class='font-medium'>Inicia</span> tu experiencia TuParty <a href='https://calendar.app.google/7nDdZeioDK9TySWXA' target='_blank' class='underline font-semibold' style='pointer-events: auto;'>reservándonos un hueco</a> para poderte explicar como <span class='font-medium'>empezar</span>.</p> <p>Nos pondremos en <span class='font-medium'>contacto</span> rápidamente para conocerte y <span class='font-medium'>entender</span> cómo podemos hacer de tu evento un <span class='font-medium'>éxito</span>.</p></div>"
                  type="calendar"
                />
                <StepItem 
                  number="2" 
                  title="Te explicamos el funcionamiento" 
                  description="<div class='flex flex-col gap-2'><p>Te <span class='font-medium'>explicamos</span> cómo funciona TuParty y te <span class='font-medium'>creamos</span> tu cuenta de organizador.</p> <p><span class='font-medium'>Definimos</span> cómo accederán los asistentes: mediante <span class='font-medium'>QR</span> (pegatinas, pantallas...) o <span class='font-medium'>enlace</span> (redes sociales, grupo de Whatsapp...).</p></div>"
                />
                <StepItem 
                  number="3" 
                  title="Confirmación de fecha y, ¡a disfrutar!" 
                  description="<div class='flex flex-col gap-2'><p>Definimos la <span class='font-medium'>fecha</span> del evento y, con todo listo, solo queda <span class='font-medium'>disfrutar</span> de la fiesta.</p> <p>¡Tu evento con TuParty será <span class='font-medium'>inolvidable</span>!</p></div>"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- Success Cases Section -->
      <section class="pt-10">
        <div class="mx-auto px-4 xs:px-12">
          <div class="text-center mb-12">
            <h2 class="font-bold text-4xl sm:text-5xl xl:text-6xl mb-4 animated-block-y">Casos de éxito</h2>
            <p class="text-xl font-medium text-tp-yellow max-w-2xl mx-auto animated-block-y">
              Eventos que ya han vivido la experiencia TuParty.
            </p>
          </div>

          <div class="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <TestimonialCard 
              :image="capeaPamplona"
              quote="Más de 500 personas interactuaron con la app."
              author="Volare Events (Pamplona)"
            />
            <TestimonialCard 
              :image="paellasMurcia"
              quote="El 80% de los asistentes votaron al menos una vez."
              author="Paellas con Lemon (Murcia)"
            />
          </div>
        </div>
      </section>

      <div id="form" class="flex flex-col items-center justify-center w-full pt-28 sm:pt-32 -mb-10 px-4 xs:px-12 animated-block-y">
        <h2 class="font-bold text-4xl sm:text-5xl xl:text-6xl mb-4 text-center">¿Listo para empezar?</h2>
        <a href="https://calendar.app.google/7nDdZeioDK9TySWXA" target='_blank' class="bg-tp-yellow text-tp-black font-semibold px-4 py-2 rounded-lg hover:bg-opacity-80 yellow-shadow uppercase">
          Empieza ya
        </a>
      </div>

      <ContactUs type="event" />
    </main>
  </div>
</template>

<script setup>
import StepItem from '@/components/Events/StepItem.vue';
import TestimonialCard from '@/components/Events/TestimonialCard.vue';
import ContactUs from '@/components/Common/ContactUs.vue';
import capeaPamplona from '@/assets/images/capea-pamplona.jpg';
import paellasMurcia from '@/assets/images/paellas-murcia.jpg';
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();
</script>

<style scoped>
@keyframes pulse-shadow {
  0% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(249, 249, 113, 1);
  }
  100% {
    box-shadow: 0 0 5px rgba(249, 249, 113, 0.5);
  }
}

.yellow-shadow {
  animation: pulse-shadow 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>