<template>
  <div class="flex flex-col items-center gap-10 pt-28 xs:pt-36 pb-20 px-8 lg:px-28 2xl:px-32 min-h-screen">
    <h1 class="text-4xl sm:text-7xl md:text-8xl xl:text-9xl font-semibold text-center animated-block-y">Un equipo con <br> gran <span class="italic">impacto</span> y <span class="italic">ambición</span></h1>
    <div class="grid gap-10 xl:gap-16 grid-cols-1 md:grid-cols-2 xl:grid-cols-3 animated-block-y">
        <TeamMember :photo="manuel" name="Manuel Pérez Bejarano" role="Co-Founder y CEO" />
        <TeamMember :photo="gonzalo" name="Gonzalo Rodríguez Terrón" role="Co-Founder y Director Creativo" />
        <TeamMember class="flex md:hidden xl:flex" :photo="ruben" name="Rubén Gómez Morales" role="Co-Founder y CTO" />
        <div class="hidden md:flex xl:hidden justify-center col-span-2">
          <TeamMember class="w-full" :photo="ruben" name="Rubén Gómez Morales" role="Co-Founder y CTO" />
        </div>
    </div>
    <div class="flex flex-col gap-4 pt-10 text-center">
        <h2 class="font-semibold text-3xl underline animated-block-y">Juntos por un propósito</h2>
        <h3 class="leading-normal font-medium text-xl animated-block-y">
            Detrás de <span class="font-semibold">TuParty</span> hay un equipo apasionado que busca <span class="font-semibold">transformar</span> cómo se vive la <span class="font-semibold">música</span> en las discotecas, creando experiencias únicas cada noche. Con experiencia en tecnología, creatividad y ventas, trabajamos juntos para ofrecer una solución que <span class="font-semibold">beneficia</span> tanto a <span class="font-semibold">DJs</span> como a <span class="font-semibold">discotecas</span>. Nuestra misión es clara: <span class="font-semibold">conectar</span> al público con los DJs y hacer que cada canción sea un momento <span class="font-semibold">especial</span>.
        </h3>
    </div>
  </div>
</template>

<script setup>
import TeamMember from '@/components/About/TeamMember.vue';
import manuel from '@/assets/images/manuel.png';
import gonzalo from '@/assets/images/gonzalo.png';
import ruben from '@/assets/images/ruben.png';
import { useAnimationObserver } from '@/composables/useAnimationObserver';

useAnimationObserver();
</script>