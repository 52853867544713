<template>
  <div class="grid gap-2 sm:gap-4 lg:gap-6 grid-cols-1 xxs:grid-cols-2 sm:grid-cols-3 items-center justify-center w-full pt-10 sm:pt-16 pb-0 px-8 lg:px-28">
    <AnimatedNumber :targetNumber="7000" dataType="Usuarios" :isVisible="isVisible"/>
    <AnimatedNumber :targetNumber="4" dataType="Ciudades" :isVisible="isVisible" />
    <AnimatedNumber class="hidden sm:flex" :targetNumber="10" dataType="Discotecas y DJs" :isVisible="isVisible" />

    <div class="flex sm:hidden justify-center xxs:col-span-2 sm:col-span-1">
      <AnimatedNumber class="w-full" :targetNumber="10" dataType="Discotecas y DJs" :isVisible="isVisible" />
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted } from 'vue';
import AnimatedNumber from '@/components/Main/AnimatedNumber.vue';

const isVisible = ref(false);

onMounted(() => {
  const element = document.querySelector('.animated-block-y');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        isVisible.value = true;
        observer.unobserve(entry.target); 
      }
    });
  }, {
    threshold: 0.1
  });

  if (element) {
    observer.observe(element);
  }
});
</script>

<style scoped>
@media (min-width: 640px) {
    .sm\:grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}
</style>
