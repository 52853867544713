<template>
    <div class="flex flex-col gap-6 px-8 lg:px-28">
        <h1 class="hidden xl:flex font-bold text-4xl sm:text-5xl xl:text-6xl animated-block text-center sm:text-left">Solo 4 pasos</h1>
        <h1 class="xl:hidden font-bold text-4xl sm:text-5xl xl:text-6xl animated-block text-center sm:text-left">Solo 2 pasos</h1>
        <div class="hidden xl:grid gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 border-2 border-tp-yellow rounded-2xl p-10 animated-block">
            <StepComponent class="step-component animated-block" :title="stepTitle1" :image="step1Mockup" />
            <StepComponent class="step-component animated-block" :title="stepTitle2" :image="step2Mockup" />
            <StepComponent class="hidden xl:flex step-component animated-block" :title="stepTitle3" :image="step3Mockup" />
            <StepComponent class="step-component animated-block" :title="stepTitle4" :image="step4Mockup" />
        </div>
        <div class="xl:hidden grid gap-10 grid-cols-1 md:grid-cols-2 xl:grid-cols-4 border-2 border-tp-yellow rounded-lg p-10 animated-block">
            <StepComponent class="step-component animated-block" :title="stepTitle1" :image="step1Mockup" />
            <StepComponent class="step-component animated-block" :title="stepTitle2" :image="step3Mockup" />
        </div>
    </div>
</template>

<script setup>
import StepComponent from '@/components/Features/StepComponent.vue'
import step1Mockup from '@/assets/images/step-1-mockup.webp'
import step2Mockup from '@/assets/images/step-2-mockup.webp'
import step3Mockup from '@/assets/images/step-3-mockup.webp'
import step4Mockup from '@/assets/images/step-4-mockup.webp'


const stepTitle1 = "Escanea el QR"

const stepTitle2 = "Vota tu canción favorita"

const stepTitle3 = "Posiciónala en el TOP"

const stepTitle4 = "¡Disfruta de la ganadora!"

</script>