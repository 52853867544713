<template>
  <div class="text-left p-6 rounded-lg bg-tp-yellow shadow-md w-full mr-4 hover:bg-opacity-80 cursor-pointer" style="user-select: none;">
    <div class="text-yellow-400 text-2xl text-outlined">
      <span v-for="n in maxStars" :key="n" :class="{ 'text-gray-300': n > props.rating }">
        &#9733;
      </span>
    </div>
    <p class="mt-1 text-tp-black">{{ props.comment }}</p>
    <p class="mt-2 font-bold text-tp-black">{{ props.name }}</p>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  rating: {
    type: Number,
    required: true
  },
  comment: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true
  }
});

const maxStars = 5;
</script>

<style scoped>
.text-outlined {
  -webkit-text-stroke: 1px rgba(0, 0, 0, 1);
}
</style>
