<template>
  <div
    class="p-4 bg-tp-black border-2 border-tp-yellow rounded-lg transition-all"
    :class="{ 'shadow-lg': open }"
  >
    <div class="flex justify-between items-center cursor-pointer w-full" @click="toggleAnswer(index)">
      <span class="text-xl font-medium">{{ question }}</span>
      <div
        class="w-6 h-6 flex items-center justify-center transition-transform transform duration-500"
        :class="{ 'rotate-180': open }"
      >
        <!-- SVG ícono de flecha -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-5 h-5"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </div>
    </div>
    <!-- Respuesta -->
    <div
      class="transition-all overflow-hidden duration-500"
      :style="open ? 'max-height: 300px;' : 'max-height: 0;'"
    >
      <p v-if="open" class="text-md leading-6 pt-4 text-tp-yellow" v-html="answer"></p>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue';

defineProps({
  question: String,
  answer: String,
});

const open = ref(false);

const toggleAnswer = () => {
  open.value = !open.value;
};

</script>

<style scoped>
/* Ajustes visuales */
.rotate-180 {
  transform: rotate(180deg);
}

.shadow-lg {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.2);
}
</style>
